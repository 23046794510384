import { DecimalPipe } from '@angular/common';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { TableService } from 'src/app/shared/service/table.service';
import { LISTBANNERSDB, ListBannersDB } from 'src/app/shared/tables/list-banner';
import { CatalogService } from '../../catalog/catalog.service';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss'],
  providers: [TableService, DecimalPipe]
})

export class BannersComponent implements OnInit {

  public selected = [];
  
  public tableItem$: Observable<ListBannersDB[]>;
  public searchText;
  total$: Observable<number>;

  constructor(public service: TableService, private catalogService: CatalogService) {
    this.tableItem$ = service.tableItem$;
    this.total$ = service.total$;
    this.service.setUserData(LISTBANNERSDB)
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  ngOnInit(): void {
    this.catalogService.getCategoriesData().subscribe(
      (data) => {
        console.log('API Response:', data);
        // Handle the data as needed
      },
      (error) => {
        console.error('API Error:', error);
        // Handle the error
      }
    );
  }

}
