import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NavService } from '../../service/nav.service';
import { AlertService } from '../../../alert.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public right_sidebar: boolean = false;
  public open: boolean = false;
  public openNav: boolean = false;
  public isOpenMobile : boolean;
  
  public alertType: string = 'success';
  public isAlertActive: boolean = false;
  public alertText: string = 'Default alert text.';

  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  constructor(public navServices: NavService, private alertService: AlertService) { }

  collapseSidebar() {
    this.open = !this.open;
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }
  right_side_bar() {
    this.right_sidebar = !this.right_sidebar
    this.rightSidebarEvent.emit(this.right_sidebar)
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  ngOnInit() {
    this.alertService.showAlert$.subscribe((value) => {
      this.isAlertActive = value;
    });

    this.alertService.alertType$.subscribe((type) => {
      this.alertType = type;
    });

    this.alertService.alertText$.subscribe((text) => {
      this.alertText = text;
    });
  }

}
