import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CatalogService } from '../catalog.service';
import { firstValueFrom } from 'rxjs';
import { AlertService } from '../../../alert.service';

@Component({
  selector: 'app-create-attribute',
  templateUrl: './create-attribute.component.html',
  styleUrls: ['./create-attribute.component.scss']
})

export class CreateAttributeComponent implements OnInit {
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder, 
    private catalogService: CatalogService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    // initialize the form with form controls and validators
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      title: '',
      keyword: '',
      description: '',
      status: true
    });
  }

  isNameControlEmpty(): boolean {
    const nameControl = this.form.get('name');
    return nameControl.value === '';
  }
  
  // inside MyFormComponent class
  async onSubmit() {
    if (this.form.valid) {
      console.log('Form submitted:', this.form);

      const formData = new FormData();
      formData.append('name', this.form.get('name').value);
      formData.append('title', this.form.get('title').value);
      formData.append('keyword', this.form.get('keyword').value);
      formData.append('description', this.form.get('description').value);
      formData.append('status', this.form.get('status').value);

      console.log('formData:', formData);

      try {
        const response = await firstValueFrom(this.catalogService.postData(formData));
        console.log('Post successful:', response);

        // Set alert properties dynamically
        this.alertService.setShowAlert(true);
        this.alertService.setAlertType('warning'); // Change to 'danger' or 'success' as needed
        this.alertService.setAlertText('Custom alert text');
      } catch (error) {
        console.error('Error during post:', error);
        // handle the error
        this.alertService.setShowAlert(false);
      }
    } else {
      //Object.values(this.categoryForm.controls).forEach(control => control.markAsTouched());
    }
  }
}
