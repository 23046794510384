<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Add Product</h5>
                </div>
                <div class="card-body">
                    <div class="row product-adding">
                        <div class="col-xl-5">
                            <div class="add-product">
                                <div class="row">
                                    <div class="col-xl-9 xl-50 col-sm-6 col-9">
                                        <img src="assets/images/pro3/1.jpg" alt="" class="img-fluid image_zoom_1" #mainImage>
                                    </div>
                                    <div class="col-xl-3 xl-50 col-sm-6 col-3">
                                        <ul class="file-upload-product">
                                            <li *ngFor="let item of url; let i=index">
                                                <div class="box-input-file">
                                                    <!-- Ensure mainImage is passed correctly to the readUrl method -->
                                                    <input class="upload" type="file" (change)="readUrl($event, i, mainImage)">
                                                    <img class="imgUpload" alt="" [src]="item.img">
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                
                                
                            </div>
                        </div>
                        <div class="col-xl-7">
                            <form class="needs-validation" [formGroup]="productForm" novalidate>

                                <div class="add-product-form">
                                    <div class="form-group row">
                                        <div class="row">
                                            <div class="col-md-7">
                                                <div class="form-group row">
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4">
                                                        <label for="validationCustom0">ID <span>*</span></label>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-3 col-md-3 col-sm-4">
                                                        <input class="form-control" id="validationCustom0" type="text"
                                                            disabled required="">
                                                    </div>
                                                    <div class="col-xl-4 col-lg-3 col-md-3 col-sm-4">
                                                        <label for="validationCustom0"><small>Auto
                                                                Generated</small></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-5">
                                                <div class="form-group row">
                                                    <label class="col-xl-3 col-lg-3 col-md-3 col-sm-4">Status</label>
                                                    <div class="col-xl-9 col-lg-9 col-md-9 col-sm-8">
                                                        <div class="checkbox checkbox-primary">
                                                            <input type="checkbox" id="status" formControlName="status"
                                                                data-original-title="" title="">
                                                            <label for="status">Active</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-sm-4 ">
                                            <label for="validationCustom01" class="mb-0">Code
                                                :</label>
                                        </div>
                                        <div class="col-xl-8 col-sm-7">
                                            <input class="form-control" id="validationCustom01" type="text"
                                                [formControl]="productForm.controls['name']" required>
                                            <div *ngIf="productForm.controls.name.touched && productForm.controls.name.errors?.required"
                                                class="text text-danger">
                                                Code is required.
                                            </div>
                                        </div>

                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-sm-4 ">
                                            <label for="validationCustom01" class="mb-0">Product Name
                                                :</label>
                                        </div>
                                        <div class="col-xl-8 col-sm-7">
                                            <input class="form-control" id="validationCustom01" type="text"
                                                [formControl]="productForm.controls['name']" required>
                                            <div *ngIf="productForm.controls.name.touched && productForm.controls.name.errors?.required"
                                                class="text text-danger">
                                                Product Name is required.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-sm-4 ">
                                            <label for="validationCustom01" class="mb-0">Brand
                                                :</label>
                                        </div>
                                        <div class="col-xl-8 col-sm-7">
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected disabled>Select Brand</option>
                                                <!-- <option value="1">HP</option> -->
                                              
                                            </select>
                                        </div>

                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-sm-4 ">
                                            <label for="validationCustom01" class="mb-0">Categories
                                                :</label>
                                        </div>
                                        <div class="col-xl-8 col-sm-7">
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected disabled>Select Category</option>
                                                <option value="1">Shirts</option>
                                                <!-- <option value="2">Intel</option>
                                                <option value="3">POSPRO</option> -->
                                            </select>
                                        </div>

                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-sm-4">
                                            <label for="validationCustom02" class="mb-0">Price :</label>
                                        </div>
                                        <div class="col-xl-8 col-sm-7">
                                            <input class="form-control" id="validationCustom02" type="number"
                                                [formControl]="productForm.controls['price']" required="">
                                        </div>
                                        <div *ngIf="productForm.controls.price.touched && productForm.controls.price.errors?.required"
                                            class="text text-danger">
                                            Product Price is required.
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-sm-4">
                                            <label for="validationCustom02" class="mb-0">Discount % :</label>
                                        </div>
                                        <div class="col-xl-8 col-sm-7">
                                            <input class="form-control" id="validationCustom02" type="number"
                                                [formControl]="productForm.controls['price']" required="">
                                        </div>
                                        <div *ngIf="productForm.controls.price.touched && productForm.controls.price.errors?.required"
                                            class="text text-danger">
                                            Product Price is required.
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-3 col-lg-3 col-md-3 col-sm-4">Available Stocks</label>
                                        <div class="col-xl-9 col-lg-9 col-md-9 col-sm-8">
                                            <div class="checkbox checkbox-primary">
                                                <input type="checkbox" id="statusCheckbox" >
                                                <label for="statusCheckbox">In-stock / Out-stock </label>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="form-group row">
                                        <div class="col-xl-3 col-sm-4">
                                            <label for="validationCustom03" class="mb-0">Product Code
                                                :</label>
                                        </div>
                                        <div class="col-xl-8 col-sm-7">
                                            <input class="form-control" id="validationCustom03" type="number"
                                                [formControl]="productForm.controls['code']" required="">
                                        </div>
                                        <div *ngIf="productForm.controls.code.touched && productForm.controls.code.errors?.required"
                                            class="text text-danger">
                                            Product <Code></Code> is required.
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-sm-4">
                                            <label for="exampleFormControlSelect1" class="mb-0">Select
                                                Size
                                                :</label>
                                        </div>
                                        <div class="col-xl-8 col-sm-7">
                                            <select class="form-control digits" id="exampleFormControlSelect1">
                                                <option>Small</option>
                                                <option>Medium</option>
                                                <option>Large</option>
                                                <option>Extra Large</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-sm-4">
                                            <label class="mb-0">Total Products :</label>
                                        </div>
                                        <div class="col-xl-8 col-sm-7">
                                            <div class="qty-box1">
                                                <div class="input-group">
                                                    <i class="fa fa-minus btnGtr1" (click)=decrement()></i>
                                                    <input class="touchspin1 text-center" name="counter"
                                                        [value]="counter" type="text">
                                                    <i class="fa fa-plus btnLess1" (click)=increment()></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-sm-4"><label>Description :</label></div>
                                        <div class="col-xl-8 col-sm-7">
                                            <div class="ps-0 description-sm ">
                                                <ckeditor [editor]="Editor" data="<p>Hello, world!</p>"></ckeditor>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-sm-4">
                                            <label for="validationCustom02" class="mb-0">Specification :</label>
                                        </div>
                                        <div class="col-xl-8 col-sm-7">
                                            <div class="row">
                                                <table class="table table-striped table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">   <a href="" style="display: flex;justify-content: center;"> <img style="width: 22px;" src="../../../../assets/images/add.svg" alt=""></a></th>
                                                            <th scope="col">Title</th>
                                                            <th scope="col">Description</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">
                                                                <a href="" style="display: flex;justify-content: center;"> <img style="width: 20px;" src="../../../../assets/images/delete-icon.svg" alt=""></a>
                                                            </th>
                                                            <td>
                                                                <select class="form-control digits" id="exampleFormControlSelect1">
                                                                    <option>Small</option>
                                                                    <option>Medium</option>
                                                                    <option>Large</option>
                                                                    <option>Extra Large</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                               
                                                                    <input class="form-control" id="validationCustom02" type="text"
                                                                        [formControl]="productForm.controls['price']" required="">
                                                               
                                                            </td>
                                                         
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-xl-3 col-sm-4">
                                            <label for="validationCustom02" class="mb-0">Attributes :</label>
                                        </div>
                                        <div class="col-xl-8 col-sm-7">
                                            <div class="row">
                                                <table class="table table-striped table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">   <a href="" style="display: flex;justify-content: center;"> <img style="width: 22px;" src="../../../../assets/images/add.svg" alt=""></a></th>
                                                            <th scope="col">Attributes</th>
                                                            <th scope="col">Attribute Value</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">
                                                                <a href="" style="display: flex;justify-content: center;"> <img style="width: 20px;" src="../../../../assets/images/delete-icon.svg" alt=""></a>
                                                            </th>
                                                            <td>
                                                                <select class="form-control digits" id="exampleFormControlSelect1">
                                                                    <option selected disabled>Select</option>
                                                                    <option>Color</option>
                                                                    <option>Size</option>
                                                                    
                                                                    
                                                                </select>
                                                            </td>
                                                            <td>
                                                               
                                                                <select class="form-control digits" id="exampleFormControlSelect1">
                                                                    <option selected disabled>Select</option>
                                                                </select>                       
                                                            </td>
                                                         
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                      
                                    </div>
                                    <!-- <div class="form-group row">
                                        <div class="col-xl-3 col-sm-4">
                                            <label for="validationCustom02" class="mb-0">Images :</label>
                                        </div>
                                        <div class="col-xl-8 col-sm-7">
                                            <div class="row">
                                               <input type="file" class="form-control">
                                            </div>
                                        </div>
                                      
                                    </div> -->
                                </div>
                            </form>
                            <div class="form-group row mb-0">
                                <div class="col-sm-8">
                                    <div class="product-buttons">
                                        <button type="submit" class="btn btn-primary" [disabled]="">Save</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->