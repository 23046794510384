export interface ListCategoriesDB {
    name: string;
    meta_title: string;
    meta_keyword: string;
    meta_Description: string;
    payment_class:string;
    status: string;
    checked?: boolean;
}

export const LISTCATEGORYDB: ListCategoriesDB[] = [
    {
        name: " Electronic",
        meta_title: " Electronic",
        meta_keyword: " Electronic",
        meta_Description: " Electronic",
        "status":"Active",
        "payment_class": 'badge-success',
        checked: false
    },
    {
        name: " Fashion",
        meta_title: " Fashion",
        meta_keyword: " Fashion",
        meta_Description: " Fashion",
        "status":"Active",
        "payment_class": 'badge-success',
        checked: false
    },
    {
        name: " Jewellery",
        meta_title: " Jewellery",
        meta_keyword: " Jewellery",
        meta_Description: " Jewellery",
        "status":"Active",
        "payment_class": 'badge-success',
        checked: false
    },
    {
        name: " Sports",
        meta_title: " Sports",
        meta_keyword: " Sports",
        meta_Description: " Sports",
        "payment_class": 'badge-success',
        "status":"Active",
        checked: false
    }
]
