import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from './profile/profile.component';
import { ListCountriesComponent } from './list-countries/list-countries.component';
import { CreateCountryComponent } from './create-country/create-country.component';
import { ListStateComponent } from './list-state/list-state.component';
import { CreateStateComponent } from './create-state/create-state.component';
import { ListCityComponent } from './list-city/list-city.component';
import { CreateCityComponent } from './create-city/create-city.component';
import { BannersComponent } from './banners/banners.component';
import { CreateBannersComponent } from './create-banners/create-banner.component';
import { ListSlidersComponent } from './list-sliders/list-sliders.component';
import { CreateSlidersComponent } from './create-sliders/create-sliders.component';
import { ListDeliveryChargesComponent } from './list-delivery_charges/list-delivery_charges.component';
import { CreateDeliveryChargesComponent } from './create-delivery_charges/create-delivery_charges.component';
import { ListPaymentMethodsComponent } from './list-payment_methods/list-payment_methods.component';
import { CreatePaymentMethodsComponent } from './create-payment_methods/create-payment_methods.component';
import { ListSMSHISTORYComponent } from './list-sms_history/list-sms_history.component';

const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    data: {
      title: "Profile",
      breadcrumb: "Profile"
    }
  },
  {
    path: 'list-countries',
    component: ListCountriesComponent,
    data: {
      title: "List Countries",
      breadcrumb: "List Countries"
    }
  },
  {
    path: 'create-country',
    component: CreateCountryComponent,
    data: {
      title: "Create Country",
      breadcrumb: "Create Country"
    }
  },
  {
    path: 'list-state',
    component: ListStateComponent,
    data: {
      title: "List State",
      breadcrumb: "List State"
    }
  },
  {
    path: 'create-state',
    component: CreateStateComponent,
    data: {
      title: "Create State",
      breadcrumb: "Create State"
    }
  },
  {
    path: 'list-city',
    component: ListCityComponent,
    data: {
      title: "List City",
      breadcrumb: "List City"
    }
  },
  {
    path: 'create-city',
    component: CreateCityComponent,
    data: {
      title: "Create City",
      breadcrumb: "Create City"
    }
  },
  {
    path: 'list-banners',
    component: BannersComponent,
    data: {
      title: "List Banners",
      breadcrumb: "List Banners"
    }
  },
  {
    path: 'list-banners',
    component: BannersComponent,
    data: {
      title: "List Banners",
      breadcrumb: "List Banners"
    }
  },
  {
    path: 'create-banners',
    component: CreateBannersComponent,
    data: {
      title: "Create Banners",
      breadcrumb: "Create Banners"
    }
  },
  {
    path: 'list-sliders',
    component: ListSlidersComponent,
    data: {
      title: "List Slider",
      breadcrumb: "List Slider"
    }
  },
  {
    path: 'create-sliders',
    component: CreateSlidersComponent,
    data: {
      title: "Create Slider",
      breadcrumb: "Create Slider"
    }
  },
  {
    path: 'list-delivery_charges',
    component: ListDeliveryChargesComponent,
    data: {
      title: "List Delivery Charges",
      breadcrumb: "List Delivery Charges"
    }
  },
  {
    path: 'create-delivery_charges',
    component: CreateDeliveryChargesComponent,
    data: {
      title: "Create Delivery Charges",
      breadcrumb: "Create Delivery Charges"
    }
  },
  {
    path: 'list-payment_methods',
    component: ListPaymentMethodsComponent,
    data: {
      title: "List Payment Modes",
      breadcrumb: "List Payment Modes"
    }
  },
  {
    path: 'create-payment_methods',
    component: CreatePaymentMethodsComponent,
    data: {
      title: "Create Payment Modes",
      breadcrumb: "Create Payment Modes",
    }
  },
  {
    path: 'list-sms_history',
    component: ListSMSHISTORYComponent,
    data: {
      title: "SMS History",
      breadcrumb: "SMS History",
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingRoutingModule { }
