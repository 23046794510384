import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CatalogService } from '../../catalog/catalog.service';
import { firstValueFrom } from 'rxjs';
import { AlertService } from '../../../alert.service';

@Component({
  selector: 'app-create-banner',
  templateUrl: './create-banner.component.html',
  styleUrls: ['./create-banner.component.scss']
})

export class CreateBannersComponent implements OnInit {
  categoryForm: FormGroup;
  public counter: number = 5;
  public url = [{
    img: "assets/images/pro3/1.jpg",
  },
  
  ]

  constructor(
    private formBuilder: FormBuilder, 
    private catalogService: CatalogService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    // initialize the form with form controls and validators
    this.categoryForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      title: '',
      keyword: '',
      description: '',
      status: true
    });
  }

  isNameControlEmpty(): boolean {
    const nameControl = this.categoryForm.get('name');
    return nameControl.value === '';
  }
  
  // inside MyFormComponent class
  async onSubmit() {
    if (this.categoryForm.valid) {
      // console.log('Form submitted:', this.categoryForm);

      const formData = new FormData();
      formData.append('name', this.categoryForm.get('name').value);
      formData.append('title', this.categoryForm.get('title').value);
      formData.append('keyword', this.categoryForm.get('keyword').value);
      formData.append('description', this.categoryForm.get('description').value);
      formData.append('status', this.categoryForm.get('status').value);

      // console.log('formData:', formData);

      try {
        const response = await firstValueFrom(this.catalogService.postData(formData));
        console.log('Post successful:', response);

        // Set alert properties dynamically
        this.alertService.setShowAlert(true);
        this.alertService.setAlertType('warning'); // Change to 'danger' or 'success' as needed
        this.alertService.setAlertText('Custom alert text');
      } catch (error) {
        console.error('Error during post:', error);
        // handle the error
        this.alertService.setShowAlert(false);
      }
    } else {
      //Object.values(this.categoryForm.controls).forEach(control => control.markAsTouched());
    }
  }
  readUrl(event: any, i) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url[i].img = reader.result.toString();
    }
  }
}
