<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <!-- <div class="card-header">
                    <h5>Add Category</h5>
                </div> -->
                <div class="card-body">
                    <form class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="validationCustom0">ID <span>*</span></label>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <input class="form-control" id="validationCustom0" type="text" disabled required="">
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="validationCustom0"><small>Auto Generated</small></label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="name">Name <span>*</span></label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                                <input type="text" class="form-control" id="name" formControlName="name" required=""
                                    [class.invalid]="form.get('name').invalid && form.get('name').touched">
                                <div class="error-message"
                                    *ngIf="form.get('name').hasError('required') && form.get('name').touched">
                                    Name is required.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-2 col-lg-3 col-md-3 col-sm-4">Apply Color</label>
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" id="status" formControlName="status" data-original-title=""
                                        title="">
                                    <label for="status">Yes / No</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-2 col-lg-3 col-md-3 col-sm-4">Status</label>
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" id="status" formControlName="status" data-original-title=""
                                        title="">
                                    <label for="status">Active</label>
                                </div>
                            </div>
                        </div>
                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">   <a href="" style="display: flex;justify-content: center;"> <img style="width: 22px;" src="../../../../assets/images/add.svg" alt=""></a></th>
                                    <th scope="col">Value</th>
                                    <th scope="col">Color</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">
                                        <a href="" style="display: flex;justify-content: center;"> <img style="width: 22px;" src="../../../../assets/images/delete.svg" alt=""></a>
                                    </th>
                                    <td>
                                        <input type="text" class="form-control">
                                    </td>
                                    <td>
                                        <input type="text" class="form-control">
                                    </td>
                                    <td>
                                        
                                            <input type="color">
                                        
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <button type="submit" class="btn btn-primary" [disabled]="isNameControlEmpty()">Save</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->