import { Component, OnInit } from '@angular/core';
import { doughnutData, pieData } from '../../shared/data/chart';
import { TableService } from 'src/app/shared/service/table.service';
import { Observable } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { ORDERDB, OrderDB } from 'src/app/shared/tables/orders';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [TableService, DecimalPipe],
})

export class DashboardComponent implements OnInit {

  public doughnutData = doughnutData;
  public pieData = pieData;

  public tableItem$: Observable<OrderDB[]>;
  total$: Observable<number>;

  constructor(
    public service: TableService,
    public servicePayment: TableService
  ) {
    Object.assign(this, { doughnutData, pieData })
    this.tableItem$ = service.tableItem$;
    this.total$ = service.total$;
    this.service.setUserData(ORDERDB)
  }

  ngOnInit() {
  }

}
