import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Page Component
import { ListCategoryComponent } from './list-category/list-category.component';
import { CreateCategoryComponent } from './create-category/create-category.component';
import { ListBrandComponent } from './list-brand/list-brand.component';
import { CreateBrandComponent } from './create-brand/create-brand.component';
import { ListAttributeComponent } from './list-attribute/list-attribute.component';
import { CreateAttributeComponent } from './create-attribute/create-attribute.component';
import { ListSpecificationComponent } from './list-specification/list-specification.component';
import { CreatespecificationComponent } from './create-specification/create-specification.component';
import { ListProductsComponent } from './list-products/list-products.component';
import { CreateProductComponent } from './create-product/create-product.component';
import { CreateMostSellingComponent } from './create-most-selling/create-most-selling.component';
import { ListMostSellingComponent } from './list-most-selling/list-most-selling.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list-category',
        component: ListCategoryComponent,
        data: {
          title: "List Category",
          breadcrumb: "List Category"
        }
      },
      {
        path: 'create-category',
        component: CreateCategoryComponent,
        data: {
          title: "Create Category",
          breadcrumb: "Create Category"
        }
      },
      {
        path: 'list-brand',
        component: ListBrandComponent,
        data: {
          title: "List Brand",
          breadcrumb: "List Brand"
        }
      },
      {
        path: 'create-brand',
        component: CreateBrandComponent,
        data: {
          title: "Create Brand",
          breadcrumb: "Create Brand"
        }
      },
      {
        path: 'list-attribute',
        component: ListAttributeComponent,
        data: {
          title: "List Attribute",
          breadcrumb: "List Attribute"
        }
      },
      {
        path: 'create-attribute',
        component: CreateAttributeComponent,
        data: {
          title: "Create Attribute",
          breadcrumb: "Create Attribute"
        }
      },
      {
        path: 'list-specification',
        component: ListSpecificationComponent,
        data: {
          title: "List Specification",
          breadcrumb: "List Specification"
        }
      },
      {
        path: 'create-specification',
        component: CreatespecificationComponent,
        data: {
          title: "Create Specification",
          breadcrumb: "Create Specification"
        }
      },
      {
        path: 'list-product',
        component: ListProductsComponent,
        data: {
          title: "List Product",
          breadcrumb: "List Product"
        }
      },
      {
        path: 'create-product',
        component: CreateProductComponent,
        data: {
          title: "Create Product",
          breadcrumb: "Create Product"
        }
      },
      {
        path: 'create-most-selling',
        component: CreateMostSellingComponent,
        data: {
          title: "Create Most Selling Product",
          breadcrumb: "Create Most Selling Product"
        }
      },
      {
        path: 'list-most-selling',
        component: ListMostSellingComponent,
        data: {
          title: "List Most Selling Product",
          breadcrumb: "List Most Selling Product"
        }
      },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CatalogRoutingModule { }
