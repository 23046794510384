<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <!-- <div class="card-header">
                    <h5>Add Category</h5>
                </div> -->
                <div class="card-body">
                    <form class="needs-validation" [formGroup]="categoryForm" (ngSubmit)="onSubmit()">
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="validationCustom0">ID <span>*</span></label>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <input class="form-control" id="validationCustom0" type="text" disabled required="">
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="validationCustom0"><small>Auto Generated</small></label>
                            </div>
                        </div>
                     
                       
                       
                       
                        <div class="form-group row">
                            <div class="col-xl-2 col-sm-4 ">
                                <label for="validationCustom01" class="mb-0">Page Name
                                    :</label>
                            </div>
                            <div class="col-xl-10 col-sm-7">
                                <select class="form-select" aria-label="Default select example">
                                    <option selected disabled>Select</option>
                                    <option value="1">Home</option>
                                </select>
                            </div>

                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-sm-4 ">
                                <label for="validationCustom01" class="mb-0">Location
                                    :</label>
                            </div>
                            <div class="col-xl-10 col-sm-7">
                                <select class="form-select" aria-label="Default select example">
                                    <option selected disabled>Select</option>
                                    <option value="1">Top Banners</option>
                                    <option value="2">Middle Banners</option>
                                </select>
                            </div>

                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-sm-4 ">
                                <label for="validationCustom01" class="mb-0">Type
                                    :</label>
                            </div>
                            <div class="col-xl-10 col-sm-7">
                                <select class="form-select" aria-label="Default select example">
                                    <option selected disabled>Select</option>
                                    <option value="1">Image</option>
                                    <option value="2">Vedio</option>
                                </select>
                            </div>

                        </div>
                        <div class="form-group row">
                            <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4">
                                <label for="name">Redirection Url</label>
                            </div>
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                                <input type="text" class="form-control" id="name" formControlName="name" >
                                
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-2 col-lg-3 col-md-3 col-sm-4">Status</label>
                            <div class="col-xl-10 col-lg-9 col-md-9 col-sm-8">
                                <div class="checkbox checkbox-primary">
                                    <input type="checkbox" id="status" formControlName="status" data-original-title="" title="">
                                    <label for="status">Active</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-12">
                            <div class="add-product">
                                <div class="row">
                                    <div  class="col-xl-9 xl-50 col-sm-6 col-9">
                                         
                                        
                                        <ul class="file-upload-product">
                                            <li *ngFor="let item of url; let i=index">
                                                <div class="box-input-file sizz" style="">
                                                    <input class="upload sizz" type="file" (change)="readUrl($event,i)">
                                                    <img class="img-fluid image_zoom_1 sizz" alt="" [src]="item.img">
                                                    
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-xl-3 xl-50 col-sm-6 col-3">
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary" [disabled]="isNameControlEmpty()">Save</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->